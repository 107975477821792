import React, { useState } from 'react'
import Header from '../separate/Header'
import Lottie from "lottie-react";
import Doodles from '../assets/images/vertendi/lotties/doodles.json'
import PlainPausePlay from '../assets/images/vertendi/lotties/plainPasePlay.json'
import PlainPlayer from '../assets/images/vertendi/lotties/plainPlayer.json'
import { NavLink } from 'react-router-dom';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Col, Container, Row } from 'react-bootstrap';
import Footer from '../separate/Footer';

const CommunityGuidelines = () => {
    const [activeCont,setActiveCont] = useState(0)
    const [guideCont,setGuideCont] = useState([
        {
            title : "Community Guidelines",
            cont : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum hendrerit hendrerit lorem, sed pharetra dolor convallis sit amet. Phasellus dignissim convallis tempor. Nam sit amet quam lacus. Etiam quis urna ac nisl iaculis porta. Integer ultricies est cursus condimentum vulputate. In ac leo eu nisl dictum eleifend nec eget libero. Sed scelerisque mauris felis, elementum maximus eros maximus id. Vestibulum ac maximus lacus. Donec sapien enim, mollis nec finibus id, pretium eu ante. Pellentesque congue auctor ex et ultricies. Curabitur pharetra porttitor augue ac congue. Morbi commodo cursus orci, sit amet lobortis erat rutrum vel. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed interdum tincidunt metus ut porta. Mauris tincidunt, eros sed consequat porta, nibh urna imperdiet quam, nec placerat sem ex non odio. Integer molestie turpis nec varius lobortis."
        },
        {
            title : "Generated 5 paragraphs",
            cont : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum hendrerit hendrerit lorem, sed pharetra dolor convallis sit amet. Phasellus dignissim convallis tempor. Nam sit amet quam lacus. Etiam quis urna ac nisl iaculis porta. Integer ultricies est cursus condimentum vulputate. In ac leo eu nisl dictum eleifend nec eget libero. Sed scelerisque mauris felis, elementum maximus eros maximus id. Vestibulum ac maximus lacus. Donec sapien enim, mollis nec finibus id, pretium eu ante. Pellentesque congue auctor ex et ultricies. Curabitur pharetra porttitor augue ac congue. Morbi commodo cursus orci, sit amet lobortis erat rutrum vel. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed interdum tincidunt metus ut porta. Mauris tincidunt, eros sed consequat porta, nibh urna imperdiet quam, nec placerat sem ex non odio. Integer molestie turpis nec varius lobortis."
        },
        {
            title : "Curabitur condimentum",
            cont : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum hendrerit hendrerit lorem, sed pharetra dolor convallis sit amet. Phasellus dignissim convallis tempor. Nam sit amet quam lacus. Etiam quis urna ac nisl iaculis porta. Integer ultricies est cursus condimentum vulputate. In ac leo eu nisl dictum eleifend nec eget libero. Sed scelerisque mauris felis, elementum maximus eros maximus id. Vestibulum ac maximus lacus. Donec sapien enim, mollis nec finibus id, pretium eu ante. Pellentesque congue auctor ex et ultricies. Curabitur pharetra porttitor augue ac congue. Morbi commodo cursus orci, sit amet lobortis erat rutrum vel. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed interdum tincidunt metus ut porta. Mauris tincidunt, eros sed consequat porta, nibh urna imperdiet quam, nec placerat sem ex non odio. Integer molestie turpis nec varius lobortis."
        },
        {
            title : "Nulla facilisi",
            cont : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum hendrerit hendrerit lorem, sed pharetra dolor convallis sit amet. Phasellus dignissim convallis tempor. Nam sit amet quam lacus. Etiam quis urna ac nisl iaculis porta. Integer ultricies est cursus condimentum vulputate. In ac leo eu nisl dictum eleifend nec eget libero. Sed scelerisque mauris felis, elementum maximus eros maximus id. Vestibulum ac maximus lacus. Donec sapien enim, mollis nec finibus id, pretium eu ante. Pellentesque congue auctor ex et ultricies. Curabitur pharetra porttitor augue ac congue. Morbi commodo cursus orci, sit amet lobortis erat rutrum vel. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed interdum tincidunt metus ut porta. Mauris tincidunt, eros sed consequat porta, nibh urna imperdiet quam, nec placerat sem ex non odio. Integer molestie turpis nec varius lobortis."
        },
        {
            title : "Nulla facilisi",
            cont : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum hendrerit hendrerit lorem, sed pharetra dolor convallis sit amet. Phasellus dignissim convallis tempor. Nam sit amet quam lacus. Etiam quis urna ac nisl iaculis porta. Integer ultricies est cursus condimentum vulputate. In ac leo eu nisl dictum eleifend nec eget libero. Sed scelerisque mauris felis, elementum maximus eros maximus id. Vestibulum ac maximus lacus. Donec sapien enim, mollis nec finibus id, pretium eu ante. Pellentesque congue auctor ex et ultricies. Curabitur pharetra porttitor augue ac congue. Morbi commodo cursus orci, sit amet lobortis erat rutrum vel. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed interdum tincidunt metus ut porta. Mauris tincidunt, eros sed consequat porta, nibh urna imperdiet quam, nec placerat sem ex non odio. Integer molestie turpis nec varius lobortis."
        },
        {
            title : "Lorem ipsum ",
            cont : "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum hendrerit hendrerit lorem, sed pharetra dolor convallis sit amet. Phasellus dignissim convallis tempor. Nam sit amet quam lacus. Etiam quis urna ac nisl iaculis porta. Integer ultricies est cursus condimentum vulputate. In ac leo eu nisl dictum eleifend nec eget libero. Sed scelerisque mauris felis, elementum maximus eros maximus id. Vestibulum ac maximus lacus. Donec sapien enim, mollis nec finibus id, pretium eu ante. Pellentesque congue auctor ex et ultricies. Curabitur pharetra porttitor augue ac congue. Morbi commodo cursus orci, sit amet lobortis erat rutrum vel. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae; Sed interdum tincidunt metus ut porta. Mauris tincidunt, eros sed consequat porta, nibh urna imperdiet quam, nec placerat sem ex non odio. Integer molestie turpis nec varius lobortis."
        },

    ])
  return (
    <>
      <Header/>
      <div className='vert__communityGuide'>
      <img src={require('../assets/images/vertendi/leftBlurBall.png')} className="vert__exploreBlurBall" />
        <img src={require('../assets/images/vertendi/rightBlurBall.png')} className="vert__exploreRightBlurBall" />
        <div className="rightDoodlesHolder">
          <Lottie animationData={Doodles} loop />
          </div>
          <div className="leftDoodlesHolder">
          <Lottie animationData={Doodles} loop />
          </div>
          <div className="explorePauseplayHolder">
            <Lottie animationData={PlainPausePlay} loop />  
          </div>
          <div className="explorePlayerHolder">
              <Lottie animationData={PlainPlayer} loop />
          </div>
          {/* <section className="tf-page-title style-2" data-aos="fade-right">
                    <div className="tf-container custom__vertContainer container">
                        <div className="row">
                            <div className="col-md-12">

                                <ul className="breadcrumbs firaFont">
                                    <li><NavLink to="/">Home <MdKeyboardArrowRight /></NavLink></li>
                                    <li>Subscription</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </section> */}
                <Container className='custom__vertContainer vert__subscriptionsContainer mt-5'>
                    <Row className='align-items-center'>
                        <Col lg={5} md={6} className='mb-5 mb-md-0'>
                            <h3 className='vert__guideTitle crossfitMid mb-3'>Community Guidelines</h3>
                            <p className='vert__guideHinter firaFont pt-5'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum hendrerit hendrerit lorem, sed pharetra dolor convallis sit amet.</p>
                        </Col>
                        <Col lg={{span:5,offset:1}} md={6}>
                            <div className='vert__guideEmbeder'>
                            <iframe width="100%" height="400" src="https://www.youtube.com/embed/-Pg819il8lY?si=nEgihFjNyacIJ9jf" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                            </div>
                        </Col>
                    </Row>

    <div className='vert__taberHolderer pt-5'>
                    <Row className='mt-5'>
                        <Col md={4} lg={3} className='mb-5 mb-md-0'>
                            <div className='vert__guideTopicHolder'>
                                {guideCont?.map((item,index) => 
                                <p className={`vert__guideTopicTitle  mb-4 ${activeCont === index ? 'crossfitMid' : 'firaFont' }`} onClick={() => setActiveCont(index)}>{item?.title}</p>
                            )}
                            </div>
                        </Col>

                        <Col lg={{span:8,offset:1}} md={8}>
                            <h3 className='vert__overviewTitle robotoFont'>Overview</h3>
                           
                            <p className='vert__contentShower firaFont'>{guideCont[activeCont]?.cont}</p>
                        </Col>
                    </Row>
                    </div>
                </Container>
      </div>
      <Footer/>
    </>
  )
}

export default CommunityGuidelines
