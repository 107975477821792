

const NoData =   ()  =>  {
return(
    <>
    <div className="text-centre">
        <h3>No Data Found</h3>
   
                <div className="load-more">
                </div>
        </div>
    </>
)
}
export default NoData