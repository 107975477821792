import React, { useEffect, useState } from 'react'
import { Col, Container, Offcanvas, Row } from 'react-bootstrap'
import { NavLink } from 'react-router-dom'
import Lottie from "lottie-react";
import Clipping from '../assets/images/vertendi/lotties/clipping.json'

const VertHeader = () => {
    const [search,setSearch] = useState(false)
    console.log(!search,"dddddddddddd");
    const [isMobile,setIsMobile] = useState(false)
    const [isScollred, setIsScrolled] = useState(false);
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() =>{
        const handleResize = () =>{
            if(window.innerWidth <= 1200) setIsMobile(true)            
            else setIsMobile(false)
            
        }
        window.addEventListener('resize',handleResize);
        return () =>{
            window.removeEventListener('reset',handleResize)
        }

    },[window.innerWidth])

    useEffect(() =>{
        const handleScroll = () => {
            const scrollPosition = window.scrollY;
            if (scrollPosition > 100) {
              setIsScrolled(true);
            } else {
              setIsScrolled(false);
            }
          };
          window.addEventListener("scroll", handleScroll);
          return () => {
            window.removeEventListener("scroll", handleScroll);
          };
    },[])
    console.log(isMobile,"rrrrrrrr");
  return (
    <>
        <Container className={`custom__vertContainer vert__header py-5 ${isScollred && 'vert__headreBg'}`}>
            <Row>
                <Col xs={4} md={3} lg={2}>
                    <img src={require('../assets/images/vertendi/logo.svg').default} className='header__mainLogo' />
                </Col>
                <Col xs={8} md={9} lg={10}>
                    <div className='header__rightHolder d-flex justify-content-end align-items-center'>
                        <ul className='header__linksUl d-none d-xl-flex'>
                            <li className='position-relative'>
                                <NavLink to='' className='cmnNavLink firaFont'>Home</NavLink>
                                {/* <div className='header__lottieHolder'>
                                <Lottie animationData={Clipping} loop={true} />
                                </div> */}
                               
                            </li>
                            <li>
                                <NavLink to='' className='cmnNavLink firaFont'>Explore</NavLink>
                            </li>
                            <li>
                                <NavLink to='' className='cmnNavLink firaFont'>Create</NavLink>
                            </li>
                            <li>
                                <NavLink to='' className='cmnNavLink firaFont'>Faq</NavLink>
                            </li>
                            
                        </ul>

                        <div className='position-relative vert__headerSearch'>
                                <div className={`header__searchHolder d-flex justify-content-between align-items-center ${!search && 'hide__searchbar'}`}>
                                    <input type='text' className='header__searchbar firaFont' placeholder='Search here...' />
                                    <button className='header__searchCloser pointer' onClick={() => setSearch(!search)}><i class="fa-solid fa-xmark" /></button>
                                </div>
                               <button className='header__searchBtn' onClick={() => setSearch(!search)}><i class="fa-solid fa-magnifying-glass" /></button>
                            </div>

                        <button className='header__connectWallet firaFont'>{isMobile ? <i class="fa-solid fa-wallet" /> : 'Connect Wallet'}</button>

                        <button className='header__burgerBtn d-xl-none' onClick={handleShow}><i class="fa-solid fa-bars" /></button>
                    </div>
                </Col>
            </Row>
        </Container>


        {/* offcanvas */}
        <Offcanvas show={show} placement='end' onHide={handleClose} className="vert__headerCanvas" backdrop="static">
        
        <Offcanvas.Body>
        <div className='vert__canvaHeader d-flex justify-content-between align-items-center p-3'>
            <img src={require('../assets/images/vertendi/logo.svg').default} className='vert__canvaLogo' />
            <button className='vert__canvaCloser' onClick={handleClose}><i class="fa-solid fa-xmark" /></button>
        </div>

        <div className='vert__canvaBodyCont mt-5 p-4'>
        <ul className='header__canvaUl'>
                            <li className='position-relative mb-3'>
                                <NavLink to='' className='cmnNavLink firaFont'>Home</NavLink>
                            </li>
                            <li className='mb-3'>
                                <NavLink to='' className='cmnNavLink firaFont'>Explore</NavLink>
                            </li>
                            <li className='mb-3'>
                                <NavLink to='' className='cmnNavLink firaFont'>Create</NavLink>
                            </li>
                            <li className='mb-3'>
                                <NavLink to='' className='cmnNavLink firaFont'>Faq</NavLink>
                            </li>
                        </ul>
        </div>
         
        </Offcanvas.Body>
      </Offcanvas>
        {/* end of offcanvas */}

    </>
  )
}

export default VertHeader
