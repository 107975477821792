import React, { useEffect, useState } from "react";
import Ethereum from "../assets/images/ethuu.png";
import Binance from '../assets/images/svg/binance.png'
import Stellite from "../assets/images/svg/stellite.svg";
import Tezos from "../assets/images/svg/tezos.svg";
import Flow from "../assets/images/svg/flow.svg";
import IO from "../assets/images/svg/io.svg";
import Author from "../assets/images/banner.png";
import { Link, useNavigate } from "react-router-dom";
import ImgAudVideo from "../separate/ImgAudVideo";
import config from "./config/config";
import NoData from "./seperatemodals/nodata";
import { getRefferedUsersList } from "../actions/axioss/user.axios";
import pf_img from '../assets/images/activity.png';
import { address_showing, copydata } from "../actions/common";
import action_config from './config/config';
import { toast } from 'react-toastify';
import CopyToClipboard from "react-copy-to-clipboard";
import users from '../assets/images/user.png';
import moment from "moment";
import EmptyReferer from "./seperatemodals/EmptyReferer";

import { useSelector } from "react-redux";
export default function Referral({ datas, Loadmore, List, filter, SetTokens, Tokens, userProfile, Loader }) {
    const push = useNavigate();
    { console.log('activityyyy', datas, List, userProfile) }

    const [referrallist, setReferrallist] = useState([])
    const [totalEarn, setTotalEarn] = useState(0)

    useEffect(() => {
        getReferralUserfun()
    }, [userProfile])

    const getReferralUserfun = async () => {
        console.log('sefsef');
        const Resp = await getRefferedUsersList({ WalletAddress: userProfile.WalletAddress })
        console.log("reffered", Resp);
        setReferrallist(Resp?.data)
    }



    return (
        <>
            <div className="inner-content inventory referral_pages">
                {/* <h3 class="profilename">{userProfile.DisplayName}</h3> */}


                <h4 className="title-dashboard crossfitFont vert__subpageTitle" data-aos="fade-right">
                    Referral
                </h4>
                <div className="top_sec">
                    <div>
                        <Link to="/referraldetail" className="firaFont">Learn More <i class="fa-solid fa-arrow-up-right-from-square"></i></Link>
                    </div>
                    {/* <div>
                        <button type="button" className=" copy_btn" onClick={() => { copydata(userProfile.referralCode); toast.success("Referral Code Copied", { autoClose: 1000 }) }} >Copy referral code</button>
                    </div> */}

                    <div>

                        <CopyToClipboard
                            text={`${config.FRONT_URL}/connect/${userProfile?.referralCode}`}
                            onCopy={() =>
                                toast.success("Referral Link Copied", { autoClose: 1000 })
                            } >
                            <button type="button" className="vert__cmnOutBtn copy_btn" >Copy referral Link</button>
                            {/* <i className="fas fa-sticky-note notes_fa cur_pointer"></i> */}
                        </CopyToClipboard>
                    </div>
                </div>

          

                <div className="activity_table ref_user_tabke ref_sno">
          <div className="acti_table_header aling_image_header_width">
            <div className="acti_table_singlehead"><p>S.No</p></div>
            <div className="acti_table_singlehead"><p>Profile</p></div>
            <div className="acti_table_singlehead"><p>Name</p></div>
            <div className="acti_table_singlehead"><p>Joined Date</p></div>
            <div className="acti_table_singlehead"><p>Wallet Address</p></div>
          </div>

          <div className="aling_image_body_width">
          {referrallist.length == 0 ? <div className="noda_div_cen">
           
           <NoData />
         </div>
         :
         referrallist.map((e, key) => {
             return (

<>
<div className="acti_table_values">
               <div className="acti_table_singlevalue">
           <p> {key + 1}</p>
              
             </div>
               <div className="acti_table_singlevalue">            
               <img src={e?.Profile ? action_config.IMG_URL + "/user/" + e?.WalletAddress + '/profile/' + e?.Profile : config.profile} className="img-fluid pf_img" alt="images" />
</div>
               <div className="acti_table_singlevalue"><p>   {e.DisplayName.length > 10 ? e.DisplayName.slice(0,10)+ "..." : e.DisplayName}</p></div>
             
               <div className="acti_table_singlevalue"><p> {new Date(e.createdAt).toLocaleString()}</p></div>
               <div className="acti_table_singlevalue"><p> {address_showing(e.WalletAddress)}
               </p></div>
            
             </div>
</>
             );
           }) 
       
         
        }

        
            </div>
       
         
         
         
          </div>
          
               
            </div>
        </>
    );
}
