import config from '../../views/config/config'
import { axiosFunc, AppenData } from '../common'
import { Encryptdata, Decryptdata } from '../encryptkeys';
var token;


export const midjourney = async (msg) => {
    try {

        var xhr = new XMLHttpRequest();

        // Use JSFiddle logo as a sample image to avoid complicating
        // this example with cross-domain issues.
        xhr.open("GET", msg, true);

        // Ask for the result as an ArrayBuffer.
        xhr.responseType = "arraybuffer";

        xhr.onload = function (e) {
            // Obtain a blob: URL for the image data.
            var arrayBufferView = new Uint8Array(this.response);
            var blob = new Blob([arrayBufferView], { type: "image/jpeg" });
            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL(blob);
            var img = document.querySelector("#photo");
            img.src = imageUrl;
        };

        xhr.send();

        //         var datas= {
        //             "msg": msg,
        //             "webhookOverride": "true",
        //             "ignorePrefilter": "false"
        //           }
        //         var filess = {
        //             method: 'post',
        //             url: 'https://api.thenextleg.io/v2/imagine',
        //             headers: { 
        //               'Authorization': 'Bearer 9da0fdae-20a0-4b56-b5ca-46b7087e1565', 
        //               'Content-Type': 'application/json'
        //             },
        //             data : datas
        //           };

        //         let Resp    =   await axiosFunc(filess)
        //         console.log("MIDJOUNEY",JSON.stringify(Resp))

        //        var url=await URLL(Resp)

        // console.log("IMGURL",url)

        // let data = JSON.stringify({
        //     "imgUrl": "https://cdn.midjourney.com/a3da5bc7-3393-40df-87f1-f5a2da507feb/0_0.png"
        //   });

        //   let imguell = {
        //     method: 'post',
        //     maxBodyLength: Infinity,
        //     url: 'https://api.thenextleg.io/getImage',
        //     headers: { 
        //       'Content-Type': 'application/json', 
        //       'Authorization': 'Bearer 9da0fdae-20a0-4b56-b5ca-46b7087e1565'
        //     },
        //     data : data
        //   };

        //   let imguel= await axiosFunc(imguell)

        //   console.log("imguell",imguel)
        //   return imguel.data

    }
    catch (err) {
        console.log("ERRRRRRRR", err)
    }



}

const URLL = async (obj) => {
    if (obj.data.success) {
        var geturl = {
            method: 'get',
            url: `https://api.thenextleg.io/v2/message/${obj.data.messageId}`,
            headers: {
                'Authorization': 'Bearer 9da0fdae-20a0-4b56-b5ca-46b7087e1565',
            }
        }

        var urll = await axiosFunc(geturl)
        console.log("URLLLL", urll)

        if (urll.data.progress !== 100) {
            setTimeout(() => {
                URLL(obj)
            }, 15000);

        } else {
            if (urll.data.progress == 100) {
                return urll
            }

        }



    }
}

export const GetUserCookieToken = () => {
    token = document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=')
        console.log('egfwafwer', parts)
        return parts[0] === "token" ? decodeURIComponent(parts[1]) : r
    }, '');
}

//user ProfileCreate, Update axios Function 
export const userRegister = async (data) => {
    // console.log("UserRegister is Calling",data)
    var formdata = AppenData(data)
    var senddata = {
        method: 'post',
        url: `${config.BACK_URL}/user/create`,
        data: formdata[0],
        'headers': {
            'Content-Type': 'multipart/form-data',
            'Authorization': token
        }
    }

    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)
    // console.log('resppp',Resp,senddata,data)\
    
    return Resp.data
}


export const createImg = async (data) => {
    var senddata = {

        method: 'POST',
        url: `${config.BACK_URL}/user/generateImage`,
        'headers': {
            "Content-Type": 'application/json',
            'Authorization': token
        },
        data: { data: Encryptdata(data) }

    }
    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)



    return Resp.data

}
export const Token_MyList_Func = async (data) => {
    var senddata = {
        method: 'GET',
        url: `${config.BACK_URL}/nft/myItemList`,
        params: data
    }
    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)

    return Resp.data
}

export const FollowUnFollowFunc = async (data) => {
    var senddata = {
        method: 'POST',
        url: `${config.BACK_URL}/user/FollowUnFollowFunc`,
        data: { data: Encryptdata(data) },
        'headers': {
          
            "Content-Type": 'application/json',
            
            'Authorization': token
        }
    }
    let Resp = await axiosFunc(senddata);
    Resp.data = Decryptdata(Resp.data)

    return Resp.data;
}

export const GetLikeDataAction = async (data) => {
    var senddata = {
        method: 'POST',
        url: `${config.BACK_URL}/nft/like/list`,
       
        "Content-Type": 'application/json',
   
        data: { data: Encryptdata(data) }
    }
    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)

    return Resp.data
}

export const AddLikeAction = async (data) => {
    var senddata = {
        method: 'POST',
        url: `${config.BACK_URL}/nft/like`,
        data: { data: Encryptdata(data) },
        'headers': {
           
            "Content-Type": 'application/json',
            
            'Authorization': token
        }
    }
    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)

    return Resp.data
}

export const findOwners = async (data) => {
    var senddata = {
        method: 'GET',
        url: `${config.BACK_URL}/nft/findOwners`,
        params: data

    }
    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)

    return Resp.data
}

export const Newsletter = async (data) => {
    var senddata = {
        method: 'POST',
        url: `${config.BACK_URL}/user/newsletter`,
        'headers': {
            "Content-Type": 'application/json',
        },
        data: { data: Encryptdata(data) }
    }
    console.log('hdghgh', senddata)
    let Resp = await axiosFunc(senddata);
    Resp.data = Decryptdata(Resp.data)

    return Resp.data;
}

export const report = async (data) => {
    var senddata = {
        'method': 'post',
        'url': `${config.BACK_URL}/nft/report`,
        'headers': {
            "Content-Type": 'application/json',
        },
        data: { data: Encryptdata(data) }
    }
    let Resp = await axiosFunc(senddata);
    Resp.data = Decryptdata(Resp.data)

    return Resp?.data;
}

export const getFaqList = async () => {

    try {
        var resp = await axiosFunc({

            'method': 'GET',
            'url': `${config.ADMIN_URL}/getfaqlist`,
        })
        console.log("tokonwer resp", resp.data)
        let response =Decryptdata(resp.data)
        return response;
    }
    catch (err) { console.log("err in gettok owner", err) }
}
export const getFaqcontentList = async () => {

    try {
        var resp = await axiosFunc({

            'method': 'GET',
            'url': `${config.ADMIN_URL}/getfaqcontentslist`,
        })
        console.log("tokonwer resp", resp.data)
        let response =Decryptdata(resp.data)
        return response;
    }
    catch (err) { console.log("err in gettok owner", err) }
}

export const SearchAction = async (data) => {
    var senddata = {
        method: 'GET',
        url: `${config.BACK_URL}/nft/SearchAction`,
        params: data
    }
    let Resp = await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)

    return Resp.data
}

export const Sociallinks = async () => {

    var senddata = {
        method: 'get',
        url: `${config.BACK_URL}/user/social`
    }
    let Resp = await axiosFunc(senddata);
Resp.data = Decryptdata(Resp.data)
    return Resp.data;
}


export const getReferUserDetails = async (data) => {

    var senddata = {
        method: 'get',
        url: `${config.BACK_URL}/user/getRefferUser`,
        params: data
    }
    let Resp = await axiosFunc(senddata);
    Resp.data = Decryptdata(Resp.data)

    return Resp.data;
}

export const getReferralActivity = async (data) => {

    var senddata = {
        method: 'get',
        url: `${config.BACK_URL}/user/getReferralActivity`,
        params: data
    }
    let Resp = await axiosFunc(senddata);
    Resp.data = Decryptdata(Resp.data)

    return Resp.data;
}

export const getRefferedUsersList = async (data) => {

    var senddata = {
        method: 'get',
        url: `${config.BACK_URL}/user/getRefferedUsersList`,
        params: data
    }
    let Resp = await axiosFunc(senddata);
    Resp.data = Decryptdata(Resp.data)

    return Resp.data;
}

