import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom';


const SubscriptionCard = (props) => {
  const push = useNavigate()
    console.log(props.data,"oooooooooooo");
    const data = props.data

    const hangeNavigate = ()=>{
      console.log('dsgdaigs')
      push('/payment', { state: { data: props.data } });
    }
  return (
    <>
      <div className='vert__subscriptCard p-5'>
        <div className='vert__subscriptTop d-flex justify-content-between'>
            <h3 className='vert__planName helveticaBold'>{data.plan}</h3>
            <h3 className='vert__planName '><i>'''''</i></h3>
        </div>
        {/* <p className='firaFont subsCardDullHint'>per editor/month per editor/month</p> */}

        {/* <div className='vert__subsFeatureHolder mt-5'>
            {props.data.features.map((item) => 
            <div className='vert__subsSingleFeature mb-3'>
                <img src={item.status === 'positive' ? require('../assets/images/vertendi/approve.svg').default : require('../assets/images/vertendi/reject.svg').default} className='img-fluid' />
                <p className={`vert__subsSingleFeatureHint firaFont ${item.status === "negative" && 'vertTextStriker'}`}>{item.hint}</p>
            </div>
            )}
        </div> */}
        <h2 className='vert__subsPrice helveticaBold mt-2'> Crpto Price : { data?.cyptoPrice } Matic</h2>

        <h2 className='vert__subsPrice helveticaBold mt-2'> Fiat Price : ${ data?.price }</h2>
        <p className='firaFont vert__subsAnnualHint mt-1'> Plan Type : {data.planType} </p>

        <p className='firaFont vert__subsAnnualHint mt-1'>{data.mintLimit} : Mint Limit </p>
        <p className='firaFont vert__subsAnnualHint mt-1'> Discription : {data.description?.length > 15 ? data.description.slice(0,15).concat('...') : data?.description} </p>

{Number(data?.price) !== 0 ?  
<NavLink  to='/payment' state={{data:data}}>
        <button className='vert__subsPlanBtn outfitFont'  >Choose plan</button>
        </NavLink> : 

        <button className='vert__subsPlanBtn outfitFont'  disable={Number(data?.price) === 0 ? true : false  }>Claimed</button>
}
      </div>
    </>
  )
}

export default SubscriptionCard
