import { useEffect, useRef, useState } from 'react'
import { Col, Container, Dropdown, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import Doodles from '../../assets/images/vertendi/lotties/doodles.json'
import PlainPlayer from '../../assets/images/vertendi/lotties/plainPlayer.json'
import Lottie from 'lottie-react';
import { Navigation, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';



const { NavLink } = require('react-router-dom')
const { default: NoData } = require('../seperatemodals/nodata')
const { default: Card } = require('../Card')
const { Token_List_Func, CollectionByCreator } = require('../../actions/axioss/nft.axios')


const HotAction = () => {
  const [LikedTokenList, setLikedTokenList] = useState([]);
  const [SaleData, SetSaleData] = useState({
    All: [],
  });
  const [CreateCollectionState, SetCreateCollectionState] = useState({
    All: [],
    tab: "All",
  });
  const [category, setCategory] = useState("All");

  const payload = useSelector((state) => state.LoginReducer.AccountDetails);
  const [hotactionstate, SetHotAuctionstate] = useState(true);
  var LikeForwardRef = useRef();
  const [HotAuctionData, SetHotAuction] = useState({
    All: [],
    tab: "All",
  });
  const [filter, setFilter] = useState({
    auction: "Recent",
    collection: "new",
    sale: "LatestDrops",
  });

  var [filterview, setFilterview] = useState({
    auction: "Recently created",
    collection: "Recently created",
    sale: "Recently created",
  });
  const AuctionFilter = (val, show) => {
    setFilter({ ...filter, ...{ auction: val } });
    setFilterview({ ...filterview, ...{ auction: show } });
  };

  const HotAuction = async (data, tabs) => {
    var SendDATA = {
      TabName: tabs,
      limit: 8,
      ProfileUrl: "",
      page: 1,
      from: "Auction",
      filter: filter.auction,
    };
    let Resp = await Token_List_Func(SendDATA);
    if (Resp?.success == "success") {
      SetHotAuction({
        ...HotAuctionData,
        ...{
          [tabs]: Resp?.data,
          tab: tabs,
          filter: filter.auction,
        },
      });
      SetHotAuctionstate(false);
    } else {
      SetHotAuction({
        ...HotAuctionData,
        ...{
          [tabs]: Resp?.data,
          tab: tabs,
          filter: filter.auction,
        },
      });
      SetHotAuctionstate(false);
    }
  };

  const CollectionByCreate = async (data) => {
    var SendDATA = {
      tab: data,
      limit: 4,
      ProfileUrl: "",
      page: 1,
      from: "home",
      filter: filter.collection,
    };
    let Resp = await CollectionByCreator(SendDATA);
    // console.log('fhgngfngf',Resp)
    SetCreateCollectionState({
      ...CreateCollectionState,
      ...{
        [data]: Resp?.data,
        tab: data,
        filter: filter.collection,
      },
    });
    setCategory(data);
  };

  function LikeList(data) {
    setLikedTokenList(data);
  }
  useEffect(() => {
    // getarticles();
    // Getandselldata();
    // Getandsellnft();
    // TopCreatorFunc();
    HotAuction(1, "All");
    // HotSales("All");
    // // Collectionlist('All')
    // CollectionByCreate("All");
    // GetPromtion();
  }, []);

  useEffect(() => {
    // if(filter?.auction)
    if (
      HotAuctionData.filter !== undefined &&
      filter.auction !== HotAuctionData.filter
    ) {
      HotAuction("hi", HotAuctionData.tab);
    }
    // if (SaleData.filter !== undefined && filter.sale !== SaleData.filter) {
    //   HotSales(SaleData.tab);
    // }
    // if (
    //   CreateCollectionState.filter !== undefined &&
    //   filter.collection !== CreateCollectionState.filter
    // ) {
    //   CollectionByCreate(CreateCollectionState.tab);
    // }
  }, [filter]);
  const { Categorys } = useSelector((state) => state.LoginReducer);

  const [tabBtn,setTabBtns] = useState(Categorys)
  const [activeTab,setActiveTab] = useState('All')

    return (
        <section className="ver__landingSection mt-5">
        <div className="rightDoodlesHolder">
          <Lottie animationData={Doodles} loop />
          </div>
          <div className="leftDoodlesHolder topCreatorLeftDoodles">
          <Lottie animationData={Doodles} loop />
          </div>
          <img src={require('../../assets/images/vertendi/leftBlurBall.png')} className="banner__leftBlurBall topCreatorsLeftBlurBall" />
          <img src={require('../../assets/images/vertendi/rightBlurBall.png')} className="banner__righttBlurBall topCreatorsrightBlurBall" />
          <div className="hotAuctPlayerHolder">
              <Lottie animationData={PlainPlayer} loop />
          </div>
          <Container className="custom__vertContainer">
          <div className="ver__topCreatorsCont mt-5">
        <h2 className="vert__subTitleGreen crossfitFont">Hot <br className="d-none d-md-block" />Auctions</h2>
        <div>
        <p className="firaFont vert__cmnHint vert__cmnHintXXL">Explore our Hot Auctions for coveted NFTs, from rare works by renowned artists to emerging talents. </p>
        <p className="firaFont vert__cmnHint mb-2 vert__cmnHintXXL">Experience the excitement as collectors bid on digital masterpieces.</p>
      </div>
      </div>

      <Row className="mt-5">
        <Col lg={8} className="mb-4 mb-lg-0">
          <div className="vert__hotAuctTabHolder pb-1">
            {tabBtn?.map((item) => 
            <button className={`vert__cmnTabBtns helveticaFont ${item.label === activeTab && 'active'}`} onClick={() =>setActiveTab(item.label)}>{item.label}</button>
          )}
          </div>
        </Col>
        <Col lg={4} className="d-flex justify-content-end align-items-center">
        <Dropdown className="vert__cmnDrop firaFont">
      <Dropdown.Toggle id="dropdown-basic">
      {filterview.auction}
      </Dropdown.Toggle>

      <Dropdown.Menu className="vert__cmnDropMenu">
        <Dropdown.Item className="vert__dropItem" onClick={() => {
                          AuctionFilter("Recent", "Recently created");
                        }} >Recently Created</Dropdown.Item>
        <Dropdown.Item className="vert__dropItem"    onClick={() => {
                          AuctionFilter("BLTH", "Bid Low to High");}} >Bid Low to High</Dropdown.Item>
        <Dropdown.Item className="vert__dropItem"  onClick={() => {
                          AuctionFilter("BHTL", "Bid High to Low");
                        }}>Bid High to Low</Dropdown.Item>
        <Dropdown.Item className="vert__dropItem" onClick={() => {
                          AuctionFilter("OLD", "Oldest");
                        }} >Oldest</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
        </Col>
      </Row>

      <Swiper navigation={true} modules={[Navigation,Autoplay]} className="mySwiper bannerSwiper mt-5"
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              speed={2000}
              breakpoints={{
                320: {
                  slidesPerView: 1,
                  spaceBetween: 20,
                },
                400: {
                  slidesPerView: 1.2,
                  spaceBetween: 20,
                },
                450: {
                  slidesPerView: 1.4,
                  spaceBetween: 20,
                },
                550:{
                  slidesPerView: 1.6,
                  spaceBetween: 20,
                },
                600: {
                  slidesPerView: 1.8,
                  spaceBetween: 20,
                },
                650: {
                  slidesPerView: 1.9,
                  spaceBetween: 20,
                },
                700: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                800: {
                  slidesPerView: 2.2,
                  spaceBetween: 20,
                },
                900: {
                  slidesPerView: 2.5,
                  spaceBetween: 20,
                },
                1000: {
                  slidesPerView: 2.8,
                  spaceBetween: 20,
                },
                1100: {
                  slidesPerView: 3,
                  spaceBetween: 20,
                },
                1200: {
                  slidesPerView: 3.2,
                  spaceBetween: 20,
                },
                1300: {
                  slidesPerView: 3.6,
                  spaceBetween: 20,
                },
                1400: {
                  slidesPerView: 3.8,
                  spaceBetween: 20,
                },
                1500: {
                  slidesPerView: 4,
                  spaceBetween: 20,
                },
                1600: {
                  slidesPerView: 4.2,
                  spaceBetween: 20,
                },
                1500: {
                  slidesPerView: 4.6,
                  spaceBetween: 20,
                },
                1800: {
                  slidesPerView: 4.8,
                  spaceBetween: 20,
                },
                1900: {
                  slidesPerView: 5,
                  spaceBetween: 20,
                },
              
              }}
              >
                                   {hotactionstate == true ? (
                <>
                  <div className="text-centre">
                    <h3>Loading...</h3>
                    <div className="load-more">
                    </div>
                  </div>
                </>
              ) : payload && HotAuctionData[HotAuctionData.tab]?.length > 0? (
                HotAuctionData[HotAuctionData.tab]
                ?.slice(0, 8)
                .map((item, index) => (
                  <SwiperSlide>
                    <Card
                      product={item}
                      type="marketplace"
                      index={index}
                      LikeList={LikeList}
                      LikedTokenList={LikedTokenList}
                      setLikedTokenList={setLikedTokenList}
                      LikeForwardRef={LikeForwardRef}
                    />
                    </SwiperSlide>
                ))
              ) : (
                <NoData />
              )}

      </Swiper>
      <NavLink href='/explore' >
      <div className="hotAuctBtnHolder">

{/* <button className="vert__bannerBtn firaFont">See More<img src={require('../../assets/images/vertendi/roundCross.svg').default} className="ver__bannerArrow ml-3" /></button> */}
      </div>
</NavLink>

            </Container>
            </section>
    )
}

export default HotAction ; 