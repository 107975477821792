import axios from 'axios';
import config from '../../views/config/config'
import {  AppenData } from '../common'
import { Encryptdata, Decryptdata } from '../encryptkeys';
var token;

 const axiosFunc    =   async   (data)  =>  {
    try{
        let Resp    =   await axios(data)
        return Resp
    }
    catch(e){
        console.error(e)
        return  { data :  {success:'error'  , status : false ,msg:null , message : e.message} }
    }
  }

export const GetNftCookieToken = () => {
    return  document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=')
        console.log('egfwafwer', parts)
        return parts[0] === "token" ? decodeURIComponent(parts[1]) : r
    }, '');
}
export const generateAiTextApi = async (data) => {
    const senddata = {

        method: 'POST',
        url: `${config.BACK_URL}/ai/generatedescription`,
        data: { data: Encryptdata(data) } ,

        'headers': {
            "Content-Type": 'application/json',
            'Authorization': GetNftCookieToken()
        },

    }
    let Resp = await axiosFunc(senddata)
   
    return Resp.data


}

export const GetUserPlanApi = async (data) => {
    const mesg = typeof data 
    if(mesg === 'string'){
        const senddata = {

            method: 'GET',
            url: `${config.BACK_URL}/plans/${data}`,
            // data: { data: Encryptdata(data) } ,
    
            'headers': {
                "Content-Type": 'application/json',
                'Authorization': GetNftCookieToken()
            },
    
        }
        let Resp = await axiosFunc(senddata)
       
        return Resp.data
    }
  
return {status : false , message : 'data not found'}

}

export const GetPlanApi = async () => {
    const senddata = {

        method: 'GET',
        url: `${config.BACK_URL}/plans/usersplan`,
        // data: { data: Encryptdata(data) } ,

        'headers': {
            "Content-Type": 'application/json',
            'Authorization': GetNftCookieToken()
        },

    }
    let Resp = await axiosFunc(senddata)
   
    return Resp.data


}



export const fetchPaymentIntent = async (payload) => {


    const response = await axios({
        'method': 'POST',
        'url': `${config.BACK_URL}/transaction/create-payment-intent`,
        "data": { data: Encryptdata(payload) },
        "headers": {
            "Content-Type": 'application/json'
        },
    })


const res = Decryptdata(response)
console.log('fetchPaymentIntexxntxxx',response)

    return response.data.data.client_secret;

};


export const DownloadApi = async (payload) => {


    // const response = await axios({
    //     'method': 'GET',
    //     'url': `${config.BACK_URL}/plans/download`,
    //     "params": { data: Encryptdata(payload) },
    //     "headers": {
    //         "Content-Type": 'application/json'
    //     },
    // })

window.open(`${config.BACK_URL}/plans/download?data=${Encryptdata(payload)}`)

    // return response

};

export const downloadurl = async (data) => {
window.open(data)
    
}
export const createPlanforCrptoAPi = async (data) => {

    const response = await axios({
        'method': 'POST',
        'url': `${config.BACK_URL}/transaction/tanscation`,
        "data": { data: Encryptdata(data) },
        "headers": {
            "Content-Type": 'application/json'
        },
    })
    const res = Decryptdata(response)
    return res.data
}