import React, { useEffect, useState } from 'react'
import Header from '../separate/Header'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import Lottie from 'lottie-react'
import Doodles from '../assets/images/vertendi/lotties/doodles.json'
import PlainPausePlay from '../assets/images/vertendi/lotties/plainPasePlay.json'
import PlainPlayer from '../assets/images/vertendi/lotties/plainPlayer.json'
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Col, Container, Row } from 'react-bootstrap'
import CheckoutForm from './CheckoutForm'
import Footer from '../separate/Footer'
import { useSelector } from 'react-redux'
import useContractProviderHook from '../actions/contractProviderHook'
import { toast } from 'react-toastify'
import { createPlanforCrptoAPi } from '../actions/axioss/api'
const PaymentPage = () => {
  const location = useLocation()
  console.log('PaymentPage' , location)  
  const [ subData , SetSubdata ]= useState({})  
  const [activeTab,setAtiveTab] = useState('currency')
  const [toggele , settoggle] = useState(false)
  const { payload } = useSelector((state) => state.LoginReducer.User);
  const {web3 , web3p } =useSelector((state) => state.LoginReducer.AccountDetails) 
const ContractCall = useContractProviderHook();
  const navigate = useNavigate()
const [buttonnames , setButtonNames] = useState("Pay")
  
  const [processing , Setprocessing] = useState(false)
  useEffect(() => {
    SetSubdata(location?.state?.data)
  },[location?.state])

console.log('sdigasydfay' , web3p , web3 )
const sendCrpto = async(price)=>{
  Setprocessing(true)
  setButtonNames("Processing...")
  if(!payload?.WalletAddress){
return toast.error('Please Connect Your Wallet')
  }
 
  console.log('payloadxxxx' , payload)
const id = toast.loading('transcation is processing')
  try {
  //   const data = await ContractCall.Subcribe_ContractCALL(payload?.WalletAddress,price)


const data = await ContractCall.Send_Amount(payload?.WalletAddress,price)

if(data.status){
const load = {
  walletAddress : payload?.WalletAddress,
  planId : subData._id,
  transcationId : data.transactionHash,
}
  const res = await createPlanforCrptoAPi(load)
  setButtonNames("transaction successfully")

  toast.success('transaction successfully')
console.log("createPlanforCrptoAPi",res)
  if(res.status){
    toast.success(res.message)
  }
  navigate('/')
}else{
  toast.error('transaction failed')
  setButtonNames("try-again")

}
   console.log('Subcribe_ContractCALL' , data )



  } catch (error) {
    toast.dismiss(id);
    console.error('Error sending transaction:', error);
  }finally{
    toast.dismiss(id);
    Setprocessing(false)
  }

}

  return (
    <>
      <Header/>
      <div className='vert__subscription'>
      <img src={require('../assets/images/vertendi/leftBlurBall.png')} className="vert__exploreBlurBall" />
        <img src={require('../assets/images/vertendi/rightBlurBall.png')} className="vert__exploreRightBlurBall" />
        <div className="rightDoodlesHolder">
          <Lottie animationData={Doodles} loop />
          </div>
          <div className="leftDoodlesHolder">
          <Lottie animationData={Doodles} loop />
          </div>
          <div className="explorePauseplayHolder">
            <Lottie animationData={PlainPausePlay} loop />  
          </div>
          <div className="explorePlayerHolder">
              <Lottie animationData={PlainPlayer} loop />
          </div>
          {/* <section className="tf-page-title style-2" data-aos="fade-right">
                    <div className="tf-container custom__vertContainer container">
                        <div className="row">
                            <div className="col-md-12">

                                <ul className="breadcrumbs firaFont">
                                    <li><NavLink to="/">Home <MdKeyboardArrowRight /></NavLink></li>
                                    <li>Subscription</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </section> */}

                <Container className='custom__vertContainer vert__subscriptionsContainer mt-5'>
          <h3 className="heading faq-p crossfitFont vert__subpageTitle text-center" data-aos="fade-left">Payment Method</h3>
    <Row className='justify-content-center mt-5'>
        <Col lg={6} xl={4} md={9} sm={11}>
        <div className='vert__paymentGreenCard p-5'>
          <div className='vert__subscriptTop d-flex justify-content-between'>
            <h3 className='vert__planName helveticaBold'>{subData?.plan}</h3>
            <h3 className='vert__planName '><i>'''''</i></h3>
        </div>

        <p className='vert__paymentCardHint firaFont'>{subData?.description}</p>
        <h2 className='vert__subsPrice helveticaBold mt-5'>${activeTab === "currency" ? `${subData?.price} USD` : `${subData?.cyptoPrice} MATIC`} </h2>


          </div>
        </Col>
    </Row>

    <div className='vert__subsTabHolder d-flex justify-content-center mt-5 pb-4'>
            <div className='vert__subsTaber'>
                <button className={`vert__subsTabBtn firaFont ${activeTab === 'currency' && 'active'}`} onClick={() => setAtiveTab('currency')}>Currency</button>
                <button className={`vert__subsTabBtn firaFont ${activeTab === 'blockchain' && 'active'}`} onClick={() => setAtiveTab('blockchain')}>Blockchain</button>
            </div>
          </div>
        <Row className='justify-content-center mt-5'>
            <Col lg={6}>
            {activeTab=== 'currency' ? 
          <div className='vert__stripeHolder'>
            {payload?.WalletAddress ?   
            
            <CheckoutForm data={ subData}/>
            
            :
            <div className='d-flex justify-content-center'>
            <NavLink to="/connect"> 
          <button className='vert__goldCmnBtn firaFont'>Connect Wallet</button>
          </NavLink>
          </div>

        }
          </div>
          :
          <div className='d-flex justify-content-center'>
           {/* <NavLink to="/connect">  */}
           <button className='vert__goldCmnBtn firaFont' disabled={processing}onClick={()=>sendCrpto(subData?.cyptoPrice)}>{buttonnames} </button>
           {/* </NavLink> */}
          </div>
        }
          </Col>
        </Row>
         
          </Container>
                </div>
                <Footer/>
    </>
  )
}

export default PaymentPage
