import React, { useEffect, useState } from "react";
import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import { NavLink , Link} from "react-router-dom";
import{getCmsContent} from "../actions/axioss/cms.axios";
import { MdKeyboardArrowRight } from 'react-icons/md';

import Lottie from "lottie-react";
import Doodles from '../assets/images/vertendi/lotties/doodles.json'
import PlainPausePlay from '../assets/images/vertendi/lotties/plainPasePlay.json'
import PlainPlayer from '../assets/images/vertendi/lotties/plainPlayer.json'


export default function PrivacyPolicy(){

const [privacyPolicy,SetprivacyPolicy]=useState([])
   useEffect(()=>
   {
    privacypolicy();
   },[])
    const privacypolicy =async()=>{
   
        var resp = await getCmsContent("privacypolicy");
        // console.log("cmslist",resp.data)
        if(resp?.status)
        SetprivacyPolicy(resp.data);
    }
    return(
        <>
            <Header />
                <div className="privacy_policy all vert__privacyPolicy">
                <img src={require('../assets/images/vertendi/leftBlurBall.png')} className="vert__exploreBlurBall" />
        <img src={require('../assets/images/vertendi/rightBlurBall.png')} className="vert__exploreRightBlurBall" />
        <div className="rightDoodlesHolder">
          <Lottie animationData={Doodles} loop />
          </div>
          <div className="leftDoodlesHolder">
          <Lottie animationData={Doodles} loop />
          </div>
          <div className="explorePauseplayHolder">
            <Lottie animationData={PlainPausePlay} loop />  
          </div>
          <div className="explorePlayerHolder">
              <Lottie animationData={PlainPlayer} loop />
          </div>
                <div className="tf-container custom__vertContainer container">
                <div className="row">
                    {/* <section className="tf-page-title">
                        <div className="col-md-12">
                            <ul className="breadcrumbs firaFont" data-aos="fade-right">
                                <li><NavLink to="/">Home <MdKeyboardArrowRight/></NavLink></li>
                                <li>Privacy Policy</li>
                            </ul>
                            <h1 className="page-title-heading privacy-h crossfitFont vert__subpageTitle" data-aos="fade-left">
                            Your Privacy, Our Priority

                            </h1>
                            
                            </div>
                        </section> */}
                    </div>
                    <div className="tf-add-nft" data-aos="fade-right">
                        <div className="content" data-aos="zoom-in">
                            <div className="firaFont vert__privacyPolicyCont" dangerouslySetInnerHTML={{ __html: privacyPolicy?.answer }}></div>
                            {/* <p className="mission-p mt-1">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p> */}
                        </div>
                    </div>
                </div>
                </div>
                
            <Footer />
        </>
    )
}