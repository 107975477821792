import config from '../../views/config/config'
import {axiosFunc, AppenData} from '../common'
import { Encryptdata,Decryptdata } from '../encryptkeys'
export const Category    =   async   (data)  =>  {
    var senddata    =   {
        method  :   'GET',
        url     :   `${config.ADMIN_URL}/getcategory`,
        params:{data:Encryptdata(data)}
        }
    let Resp    =   await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp.data)
    return Resp.data
}
export const Currency    =   async   (data)  =>  {
    var senddata    =   {
        method  :   'get',
        url     :   `${config.ADMIN_URL}/currencylist`,
        params:data
        }
    let Resp    =   await axiosFunc(senddata)
    Resp.data = Decryptdata(Resp?.data)

    return Resp.data
}
export const USDPRICE = async (data) =>{
    var senddata ={
        'method': 'get',
        'url': `https://min-api.cryptocompare.com/data/price?fsym=${data}&tsyms=USD`,  
    }
    let Resp = await axiosFunc(senddata);
    
    return Resp?.data?.USD;
}
export const TOKENPRICE = async (data) =>{
    var senddata ={
        'method': 'get',
        'url': `https://api.pancakeswap.info/api/v2/tokens/${data}`,  
    }
    let Resp = await axiosFunc(senddata);
    
    return Resp?.data?.data?.price;
}
export const getCmsContent  = async(data)=>{
 
    console.log('dataaaaa',data)
    
    try{
        var resp = await axiosFunc({
          
                'method':'GET',
                'url':`${config.ADMIN_URL}/getcmslist`,
              
                'params': data ? {data:Encryptdata(data)} : {}
        })
        console.log("tokonwer resp",resp.data)
        let response = Decryptdata(resp.data)
        console.log("ressssss",response);
        return response;
    }
    catch(err){console.log("err in gettok owner",err)}

}

export const getarticle=async(data)=>{
    try{
        var resp = await axiosFunc({
            "method":"get",
            "url":`${config.ADMIN_URL}/getarticle`,
            "params" : data
        })
        let response = Decryptdata(resp.data)
        return response; 
    }
    catch(err){
       throw err
    }
}

export const getabouuser=async(data)=>{
    try{
        var resp = await axiosFunc({
            "method":"get",
            "url":`${config.ADMIN_URL}/getaboutuser`,
            "params" : {data:Encryptdata(data)}
        })
        let response = Decryptdata(resp.data)
        return response; 
    }
    catch(err){
       throw err
    }
}

export const GetRoadMap=async()=>{
    try{
        var resp = await axiosFunc({
            "method":"get",
            "url":`${config.ADMIN_URL}/getroadmap`,
        })
        let response = Decryptdata(resp.data)
        return response; 
    }
    catch(err){
       throw err
    }
}