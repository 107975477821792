import Button from "react-bootstrap/esm/Button";
import Modal from "react-bootstrap/Modal";
import { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import useContractProviderHook from "./../../actions/contractProviderHook";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { isEmpty } from "../../actions/common";
import { BidApprove } from "../../actions/axioss/nft.axios";
import ImgAudVideo from "../../separate/ImgAudVideo";
import config from "../config/config";
import { getReferUserDetails } from "../../actions/axioss/user.axios";
import { network } from "../config/network";

export function Accept({
  onhide,
  OpenPopup,
  bidder,
  closePop,
  bid,
  owner,
  item,
  file,
  type,
  thumb,
  approvestatus,
}) {
  const { currency } = useSelector((state) => state.LoginReducer);
  const { web3, accountAddress, coinBalance } = useSelector(
    (state) => state.LoginReducer.AccountDetails
  );
  const { buyerFees, sellerFees } = useSelector(
    (state) => state.LoginReducer.ServiceFees
  );
  const ContractCall = useContractProviderHook();
  const dispatch = useDispatch()
  const push = useNavigate();
  // console.log('accepppttt',item)
  const { payload } = useSelector((state) => state.LoginReducer.User);
  const [Btn, SetBtn] = useState("start");
  const [Error, SetError] = useState({});
  const [TokenQuantity, SetTokenQuantity] = useState("1");
  const [TokenBal, SetTokenBal] = useState(0);
  const [show6, setShow6] = useState(true);
  const [TokenBtn, SetTokenBtn] = useState("start");
  const[Nftbal,setNftbal]=useState()
  const [bidderDetail, setBidderDetail] = useState({})
  const handleClose6 = () => setShow6(false);
  const[Once,setOnce]= useState(true)
  const token_address =
    currency?.filter((item) => item.label == bidder?.CoinName)?.pop()
      ?.address ?? "0x7CAB80ce0E55F46378E493B584eE61aD68878f11";
  const [referredUser, setReferredUser] = useState({});
  const { Network } = useSelector(
    (state) => state.LoginReducer
  );
  // console.log('rshfrstdhtd',owner)
  useEffect(() => {
    BalCal(token_address);
  }, []);

  const BalCal = async (data) => {
    let TokenBal = await ContractCall.Token_Balance_Calculation(
      data ?? token_address,
      bidder?.TokenBidderAddress
    );
    console.log("====================================rdd");
    console.log(TokenBal);
    console.log("====================================");
    SetTokenBal(TokenBal);
  };

  console.log("aeawefefr", bidder, closePop, bid, owner, item);

  useEffect(() => {
    getReferUser();
  }, [bidder]);

  const getReferUser = async () => {
    if (!isEmpty(bidder?.WalletAddress)) {
      var getBidderdetails = await getReferUserDetails({
        WalletAddress: bidder?.WalletAddress,
      });
      setBidderDetail(getBidderdetails?.data)
      var ressp = await getReferUserDetails({
        WalletAddress: getBidderdetails?.data?.referedBy,
      });
      console.log("resssp on getrefer", ressp);

      if (ressp?.success) setReferredUser(ressp?.data);
    }

  };

  const YouWillGet = useMemo(() => {
    return ContractCall.price_calculation(
      (bidder?.TokenBidAmt * TokenQuantity).toString()
    );
  }, [bidder?.TokenBidAmt, TokenQuantity]);

  const Validation = async () => {
    var Error = {};
    if (isEmpty(TokenQuantity))
      Error.TokenQuantity = "Must Select Atleast One Token";
    else if (Number(TokenQuantity) % 1 !== 0)
      Error.TokenQuantity = "Token Quantity Must Be Valid";
    if (await ContractCall.Contract_Base_Validation())
      Error.Wal = await ContractCall.Contract_Base_Validation();
    if (!isEmpty(TokenQuantity)) {
      console.log("cheackapprovecalla", token_address, TokenQuantity, bidder?.TokenBidAmt, bidder?.TokenBidderAddress, network[Network].tradeContract, web3.utils.fromWei("10100000000000000000"), TokenQuantity * bidder?.TokenBidAmt);
      if (
        TokenQuantity * bidder?.TokenBidAmt >
        Number(web3.utils.fromWei(
          String(
            (await ContractCall.allowance_721_1155(
              token_address,
              bidder?.TokenBidderAddress,
              network[Network].tradeContract
            ))
              ? await ContractCall.allowance_721_1155(
                token_address,
                bidder?.TokenBidderAddress,
                network[Network].tradeContract
              )
              : 0
          )
        ))
      )
        Error.Wal = "Bidder Doesn't have enough Allowance";
      if (TokenQuantity * bidder?.TokenBidAmt > TokenBal)
        Error.Wal = "Bidder Doesn't have enough Balance";
      if (TokenQuantity > bidder?.Pending)
        Error.Wal = `Token Quantity Must Be less Than ${bidder?.Pending}`;
        console.log("Nftbal >= TokenQuantity",Nftbal < TokenQuantity,Nftbal, TokenQuantity);
        if(Number(Nftbal) < Number(TokenQuantity)){
          Error.Wal = `you not have enough token`
        }
    }
    return Error;
  };
  console.log("bidder?.Pending",bidder?.Pending);
  const TokenApproveCall = async () => {
    SetTokenBtn("process");
    const id = toast.loading("Approve Processing");
    const cont = await ContractCall.SetApproveStatus(
      item.ContractType == 721 || item.ContractType == "721"
        ? "Single"
        : "Multiple",
      item.ContractAddress
    );
    toast.update(id, {
      render: cont ? "Approved Successfully" : "Approved Failed",
      type: cont ? "success" : "error",
      isLoading: false,
      autoClose: 1000,
      closeButton: true,
      closeOnClick: true,
    });
    if (cont.status) {
      SetTokenBtn("done");
      SetBtn("start");
    } else SetTokenBtn("try");
  };

  const FormSubmit = async () => {
    const id = toast.loading("Accepting Token on processing");
    SetError({});
    SetBtn("process");
    var error = await Validation();
    if (!isEmpty(error)) {
      toast.update(id, {
        render: Object.values(error)[0],
        type: "error",
        isLoading: false,
        autoClose: 1000,
        closeButton: true,
        closeOnClick: true,
      });
      SetBtn("error");

      SetError(error);
    } else {
      console.log("sefreeree");
      toast.update(id, {
        render: "Ready To Place Order",
        type: "success",
        isLoading: true,
        autoClose: 1000,
        closeButton: true,
        closeOnClick: true,
      });
      console.log(
        "swefred",
        bidder?.TokenBidAmt * TokenQuantity,
        bidder?.TokenBidAmt,
        TokenQuantity,
        bidder?.CoinName,
        bidder?.TokenBidderAddress,
        bidder?.ContractAddress
      );
      console.log("ehjusehfrefrwasRefffff", bidder, referredUser, payload);
     
      let cont = await ContractCall.accept_721_1155(
        bidder?.CoinName,
        [
          item.NFTId,
          web3.utils.toWei(String(bidder?.TokenBidAmt * TokenQuantity)),
          TokenQuantity,
          bidder?.ContractType,
          web3.utils.toWei(String(referredUser?.earnPercentage ?? 0)),
          web3.utils.toWei(
            String(
              bidderDetail?.referedBy && bidderDetail?.initialBuy == false
                ? 4
                : 0
            )
          ),
        ],
        [
          bidder?.ContractAddress,
          bidderDetail?.referedBy ? bidderDetail?.referedBy : config.DEADADDRESS,
          bidder?.TokenBidderAddress,
        ]
      );
      
      if (cont) {
        var FormValue = {
          TokenBidderAddress: bidder?.TokenBidderAddress,
          NFTQuantity: TokenQuantity,
          NFTId: item.NFTId,
          ContractAddress: item.ContractAddress,
          CollectionNetwork: item.CollectionNetwork,
          ContractType: item.ContractType,
          from: "accept",
          EmailId : payload.EmailId,
          item: item,
          newOwner: {
            HashValue: cont.HashValue,
            NewTokenOwner: bidder?.TokenBidderAddress,
            NFTQuantity: TokenQuantity,
            NFTId: item.NFTId,
            NFTOwner: owner?.NFTOwner,
            PutOnSale: owner?.PutOnSale,
            PutOnSaleType: owner?.PutOnSaleType,
            TP:
              owner.PutOnSaleType == "FixedPrice"
                ? owner?.NFTPrice
                : bidder?.TokenBidAmt
                  ? bidder?.TokenBidAmt
                  : bidder?.NFTPrice,
            CN:
              owner.PutOnSaleType == "FixedPrice"
                ? owner?.CoinName
                : bidder?.CoinName,
            activity: "Accept",
            Category: item.Category,
            New_EmailId: bidder?.EmailId,
            Old_EmailId: payload?.EmailId,
            click: `${config.FRONT_URL}/info/${item.CollectionNetwork}/${item.ContractAddress}/${bidder?.TokenBidderAddress}/${owner?.NFTId}`,
            initialBuy: bidderDetail?.initialBuy,
            referedBy: bidderDetail?.referedBy,
            earnPercentage: referredUser?.earnPercentage,
            adminFeePercentage:
              Number(buyerFees) / 1e18 + Number(sellerFees) / 1e18,
          },
        };
      
        let Resp = await BidApprove(FormValue);
        
        if (payload?.initialBuy == false) {
          var newPayload = payload
          newPayload.initialBuy = true
          dispatch({
            type: 'Register_Section',
            Register_Section: {
              User: {
                payload: newPayload
              }
            }
          })
        }
        if (Resp.success == "success") {
          toast.update(id, {
            render: "Accepting Token Successfully",
            type: "success",
            isLoading: false,
            autoClose: 1000,
            closeButton: true,
            closeOnClick: true,
          });
          SetBtn("done");
          push(`/profile/${payload?.CustomUrl}`);
        } else {
          toast.update(id, {
            render: "Transaction Failed",
            type: "error",
            isLoading: false,
            autoClose: 1000,
            closeButton: true,
            closeOnClick: true,
          });
          SetBtn("try");
        }
      } else {
        toast.update(id, {
          render: "Transaction Failed",
          type: "error",
          isLoading: false,
          autoClose: 1000,
          closeButton: true,
          closeOnClick: true,
        });
        SetBtn("try");
      }
    }
  };

  const onChange = async (e, data) => {
    SetError({});
    SetBtn("start");
    SetTokenQuantity(e.target.value);
  };

  useEffect(() => {
    BalanceCheck();
  }, [item, owner]);

  async function BalanceCheck() {
    
    if(Once){
      let Nftbalance = await ContractCall.Current_NFT_Balance(owner, item);
      console.log("ownneerrsnftbusdasdynowbalittemmm", Nftbalance , owner?.NFTBalance , Nftbalance , owner?.NFTOwner , item.ContractType  , owner?.NFTOwner);
      
      if ((Number(Nftbalance) != Number(owner?.NFTBalance) &&  item.ContractType == '1155' ) || ( Nftbalance.toLowerCase() !=  owner?.NFTOwner.toLowerCase()  &&  item.ContractType == '721') ) {
       
  
        toast.warning("You won't buy at this moment please refresh you data");
        setTimeout(() => {
          push("/");
        }, 1000);
      }
     
    }

    return ()=>{ setOnce(false)}
    
  }


  return (
    <Modal
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={show6}
    >
      <Modal.Header>
        {/* <button
          type="button"
          class="btn-close"
          aria-label="Close"
          onClick={()=>{
            closePop();
            onhide();}}
        ></button> */}
        <Modal.Title id="contained-modal-title-vcenter" className="text-center crossfitFont vert__subpageTitle">
        <div className='vert__bidPopHeadHolder'>
        Accept Bid
        <button className='vert__cmnModalCloserr' onClick={ ()=>{ closePop(); onhide();}}> <i class="fa-solid fa-xmark" /></button>
        </div></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="acceptbid">
          <p className="pb-3 firaFont">
            You are to accept bid for <span>{item.NFTName}</span> from{" "}
            <span> {bidder?.DisplayName ? bidder?.DisplayName : bidder?.TokenBidderAddress}</span>
          </p>
          <h6 className="pb-4 helveticaFont">
            {bidder?.TokenBidAmt + " " + bidder?.CoinName} for{" "}
            {Number(TokenQuantity)} Edition(s)
          </h6>
          <p className="text-left pb-4">Quantity</p>
          <input
            type="text"
            name="TokenQuantity"
            id="TokenQuantity"
            value={TokenQuantity}
            onChange={(e) => onChange(e, "inp")}
            className="form-control"
            disabled={
              owner?.NFTBalance == "1" || owner?.NFTBalance == 1 ? true : false
            }
            placeholder="Enter your quantity"
          />
          <div className="servicefee mt-4 d-flex justify-content-between">
            <p>
              <b>Service Fee in %:</b>
            </p>
            <p>
              {" "}
              {web3.utils.fromWei(String(sellerFees))}% {bidder?.CoinName} 
            </p>
          </div>
          <div className="servicefee mt-3 d-flex justify-content-between">
            <p>
              <b>Royalty fee in %:</b>
            </p>
            <p> {item.NFTRoyalty}%</p>
          </div>
          <div className="servicefee mt-3 d-flex justify-content-between">
            <p>
              <b>You will get :</b>
            </p>
            <p>
              {Number(YouWillGet).toFixed(6)}
              {bidder?.CoinName}
            </p>
          </div>
          <div className="connecwalletbtns d-flex justify-content-between mt-4">
            {approvestatus === "open" && (
              <Button
                className="vert__goldCmnBtn"
                disabled={
                  TokenBtn == "process" || TokenBtn == "done" ? true : false
                }
                onClick={
                  TokenBtn == "start" || TokenBtn == "try"
                    ? TokenApproveCall
                    : null
                }
                disableRipple
              >
                {TokenBtn == "start" && "Approve"}
                {TokenBtn == "process" && "In-Progress"}
                {TokenBtn == "try" && "Try-Again"}
                {TokenBtn == "done" && "Done"}
              </Button>
            )}
            {((approvestatus !== "open") || (TokenBtn == "done")) && <Button
              className="vert__goldCmnBtn"
              disabled={
                TokenBtn == "done" ?
                Btn == "error" || Btn === "process" || Btn === "done"
                  ? true
                  : false
                  :false
              }
              onClick={Btn == "start" || Btn === "try" ? FormSubmit : null}
            >
              {(Btn == "start" && "Accept Bid") ||
                (Btn == "try" && "Try-Again") ||
                (Btn == "error" && "Error") ||
                (Btn == "done" && "Done") ||
                (Btn == "process" && "In-Progress") ||
                (Btn == "putonsale" && "List")}
            </Button>}
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
