import React, { useEffect, useState } from "react";
import Header from "../separate/Header.js";
import Footer from "../separate/Footer.js";
import { NavLink, Link } from "react-router-dom";
import { getCmsContent, getabouuser } from "../actions/axioss/cms.axios";
import { getarticle } from "../actions/axioss/cms.axios";
import aboutimg from '../assets/images/aboutimg.png';
import iconss from '../assets/images/iconss.svg';
// import aboutvideo from '../assets/images/about.mp4';
import aboutpartner from '../assets/images/aboutpartner.png';
import aboutpartner1 from '../assets/images/coinbase.png';
import aboutpartner2 from '../assets/images/aboutpartner.png';

import aboutprofile from '../assets/images/aboutprofile.png';
import museumimg from '../assets/images/museumimg.png';
import story from '../assets/images/storyys1.png';
import OwlCarousel from "react-owl-carousel-rtl";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import faqimg from "../assets/images/galaxy-7040416__480.webp";

import AOS from "aos";
import "aos/dist/aos.css";
import config from './config/config'
import { MdKeyboardArrowRight } from 'react-icons/md';

import ReactPlayer from 'react-player'

import Lottie from "lottie-react";
import Doodles from '../assets/images/vertendi/lotties/doodles.json'
import PlainPausePlay from '../assets/images/vertendi/lotties/plainPasePlay.json'
import PlainPlayer from '../assets/images/vertendi/lotties/plainPlayer.json'


export default function AboutUs() {

    const [abouttop, Setabouttop] = useState({})
    const [aboutmiddle, setaboutmiddle] = useState({})
    const [aboutvide, setaboutvide] = useState({})
    const [abtmid1, setabtmid1] = useState({})
    const [abtmid2, setabtmid2] = useState({})
    const [abtmid3, setabtmid3] = useState({})
    const [article, setArticle] = useState([])
    // const [aboutuser,setAboutuser]=useState([])
    const [status, setstatus] = useState(true)
    useEffect(() => {
        AOS.init();
        AOS.refresh();
    }, []);
    useEffect(() => {
        Aboutus();
        getarticles()
    }, [])
    const Aboutus = async () => {
        // var aboutuser=await getabouuser()
        var resp = await getCmsContent();
        var aboutusers = await getabouuser()
        if (resp?.status) {
            var aboutustop = await resp?.data?.filter(character => character?.slug === "aboutus_top")
            var aboutusmiddle = await resp?.data?.filter(character => character?.slug === 'aboutus_middle')
            var aboutusvideo = await resp?.data?.filter(character => character?.slug === 'aboutus_video')
            var aboutusmiddle1 = await resp?.data?.filter(character => character?.slug === 'aboutus_middle1')
            var aboutusmiddle2 = await resp?.data?.filter(character => character?.slug === 'aboutus_middle2')
            var aboutusmiddle3 = await resp?.data?.filter(character => character?.slug === 'aboutus_middle3')
            console.log("abouy=t", aboutustop, aboutusmiddle)
            Setabouttop(aboutustop && aboutustop[0])
            setaboutmiddle(aboutusmiddle && aboutusmiddle[0])
            setaboutvide(aboutusvideo && aboutusvideo[0])
            setabtmid1(aboutusmiddle1 && aboutusmiddle1[0])
            setabtmid2(aboutusmiddle2 && aboutusmiddle2[0])
            setabtmid3(aboutusmiddle3 && aboutusmiddle3[0])
        }
        if (aboutusers.status) {
            Setaboutlist1(aboutusers?.data)

        }


    }


    const getarticles = async () => {
        // var address = { skip, limit }
        setstatus(true)
        var resp = await getarticle();
        if (resp.status) {
            setArticle(resp.data)

            //     setblogcardss(resp.data)
            //     setSkip(skip + limit)
            //    setStatus(false)
            //     resp?.data?.length == 0 ? setblogcard(resp.data) : setblogcard([...blogcard, ...resp.data])
        }
        else {
            // setStatus(resp.status)

        }
        setstatus(false)
    }
    const options1 = {
        loop: false,
        margin: 20,
        navText: ["<i class='fa fa-chevron-left'></i>", "<i class='fa fa-chevron-right'></i>"],
        nav: true,
        dots: false,  
        smartSpeed: 1200,
        autoplay: false,
        responsive: {
            0: {
                items: 1
            },
            450:{
                items : 1.2
            },
            550:{
                items : 1.4
            },
            650: {
                items: 1.6
            },
            750: {
                items: 2
            },
            850: {
                items: 2.2
            },
            950: {
                items: 2.4
            },
            1050: {
                items: 2.8
            },
            1150: {
                items: 3
            },
            1250:{
                items:3.2
            },
            1350:{
                items:3.4
            },
            1450:{
                items:3.6
            },
            1550:{
                items:3.8
            },
            1650:{
                items:4
            },
            1750:{
                items:4.4
            },
            1850:{
                items:4.8
            },
            1900:{
                items:5
            },


        }
    }

    const [aboutlist, Setaboutlist] = useState([
        {
            "id": 1,
            "title": "2018",
        },
        {
            "id": 2,
            "title": "280+"
        },
        {
            "id": 3,
            "title": "3M+",
        },
        {
            "id": 4,
            "title": "70M+",
        },
        {
            "id": 5,
            "title": "$35B+",
        },
    ]);

    const [aboutlist1, Setaboutlist1] = useState([
        {
            "id": 1,
        },
        {
            "id": 2,
        },
        {
            "id": 3,
        },
        {
            "id": 4,
        },
        {
            "id": 5,
        },
        {
            "id": 6,
        },
        {
            "id": 7,
        },
        {
            "id": 8,
        },
    ])
    return (
        <>
            <Header />
            <div className="aboutlist abouttt vert__aboutUs">
            <img src={require('../assets/images/vertendi/leftBlurBall.png')} className="vert__exploreBlurBall" />
        <img src={require('../assets/images/vertendi/rightBlurBall.png')} className="vert__exploreRightBlurBall" />
        <div className="rightDoodlesHolder">
          <Lottie animationData={Doodles} loop />
          </div>
          <div className="leftDoodlesHolder">
          <Lottie animationData={Doodles} loop />
          </div>
          <div className="explorePauseplayHolder">
            <Lottie animationData={PlainPausePlay} loop />  
          </div>
          <div className="explorePlayerHolder">
              <Lottie animationData={PlainPlayer} loop />
          </div>
                <div className="tf-container custom__vertContainer container">
                    {status == true ? <>
                        <div className="text-centre">
                            <h3>Loading...</h3>
                        </div>
                    </> : <>

                        <div className="row">
                            <section className="tf-page-title">
                                <div className="col-md-12">
                                    <ul className="breadcrumbs firaFont" data-aos="fade-right">
                                        <li><NavLink to="/">Home <MdKeyboardArrowRight /></NavLink></li>
                                        <li>Aboutus </li>
                                    </ul>
                                    {/* <h4 className="page-title-heading" data-aos="fade-left">
                                    Aboutus</h4> */}

                                </div>
                            </section>
                        </div>

                        {/* <div className="tf-add-nft" data-aos="fade-right">
                        <div className="content" data-aos="zoom-in"> */}
                        {/* <div dangerouslySetInnerHTML={{ __html: about?.answer }}></div> */}
                        {/* <h3 className="mission mb-4">Our Mission</h3>
                            <h4 className="mission mt-3 mb-4">An NFT purchased through The Naifty has an immediate and direct impact on a nonprofit and an artist.</h4>
                            <p className="mission-p mt-5">By merging the popularity of NFTs with an accessible marketplace where payment can be made with a regular credit card, The Naifty helps nonprofits raise funds for vital social justice projects. We understand that TIC’s technology gives nonprofits innovative tools, and that philanthropy still needs to be personal. Showcasing the individual stories of artists serves as the beating heart of The Naifty and illuminates the human impact of your NFT.</p> */}
                        {/* </div>
                    </div> */}

                        <div className="aboutus pb-5">

                            <section className="aboutbanner pt-3 pb-5">
                                <div className="fullwidth">
                                    <div className="row align-items-center">
                                        <div className="col-md-6 pt-3" data-aos="fade-up">
                                            <h4 className="about-heading crossfitFont vert__subpageTitle mb-3">{abouttop?.description}</h4>
                                            <p className="firaFont vert__aboutHint">
                                                <div dangerouslySetInnerHTML={{ __html: abouttop?.answer?.length > 100 ? abouttop?.answer.slice(0, 900) : abouttop?.answer }} />
                                                
                                                </p>
                                        </div>
                                        <div className="col-md-6  pt-3" data-aos="fade-down">
                                          
                                            {aboutimg && <img className="w-100" src={`${config.IMG_URL}/cmsimg/${abouttop?.img}`} alt="" />}
                                           
                                            
                                        </div>
                                    </div>
                                </div>
                            </section>

                            {/* 
                        <section className="aboutlists pt-5 pb-5">
                            <div className="row mt-3 justify-content-center align-items-center">
                                {aboutlist.map((e, i) =>
                                    <div className="col-xl-2 col-lg-3 col-md-4 col-sm-4" data-aos="zoom-in" data-aos-duration="1500">
                                        <div className="card card1">
                                            <img className="cardiconss" src={iconss} alt="aboutimg" />
                                            <div className="content text-center all1">
                                                <div className="title mt-2">
                                                    <p className="listp">{e.title}</p>
                                                </div>
                                                <div className="price">
                                                    <span className="price-eth cardvalue">Lorem Ipsum</span>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                )}
                            </div>
                        </section> */}



                            <section className="whoisnaifty pt-5 pb-5">
                                <h4 className="text-center about-heading mb-3 crossfitFont vert__subpageTitle">Our Vision</h4>
                                <p className="small-width pb-4 firaFont">Vertend's vision is to lead the NFT space with a pioneering platform where art, Music, and fitness converge, fostering authentic digital ownership and a community that values art and real-world connections.
                                </p>
                                <div className="text-center aboutvideos mt-3" data-aos="fade-up">
                                    {/* <video width="750" height="500" controls >
                                    <source src={`${config.IMG_URL}/cmsimg/${aboutvide.img}`} type="video/mp4" />
                                </video> */}

                                    <ReactPlayer controls url={`${config.IMG_URL}/cmsimg/${aboutvide?.img}`} />

                                </div>
                            </section>

                            <section className="aboutstory pt-4 pb-5">
                                <div className="row align-items-center">
                                    <div className="col-md-6 pt-2 text-right" data-aos="fade-up" data-aos-duration="1500">
                                        {/* {aboutmiddle?.img?.length >0 && aboutmiddle?.img?.map((item)=>(<img src={`${config.IMG_URL}/cmsimg/${item}`} alt="" />)) }  */}
                                        {aboutmiddle && <img src={`${config.IMG_URL}/cmsimg/${aboutmiddle?.img}`} className="img-fluid" alt="" />}
                                        {/* {aboutmiddle && <img src={`${config.IMG_URL}/cmsimg/${aboutmiddle?.img[1]}`} alt="" />}
                                  {aboutmiddle && <img src={`${config.IMG_URL}/cmsimg/${aboutmiddle?.img[2]}`} alt="" />} */}
                                    </div>
                                    <div className="col-md-6 pt-2" data-aos="fade-down" data-aos-duration="1500">
                                        <div className="oursstory">
                                            <h3 className="about-heading crossfitFont vert__subpageTitle">{aboutmiddle?.description}</h3>
                                            <p className="pt-3 firaFont"><div
                                                dangerouslySetInnerHTML={{ __html:  aboutmiddle?.answer }} />
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </section>


                            <section className="aboutpartner pt-5 pb-5">
                                <h4 className="text-center about-heading crossfitFont vert__subpageTitle mb-3">{abtmid1?.description}</h4>
                                <p className="small-width helveticaFont"><div
                                    dangerouslySetInnerHTML={{ __html:  abtmid1?.answer }} /></p>
                                <div className="partnermax">
                                    <div className="row pb-5" data-aos="zoom-in" data-aos-duration="1500">
                                        <div className="col-md-4 text-center">
                                            <img className="partnericon w-100" src={aboutpartner} alt="aboutimg" />
                                        </div>
                                        <div className="col-md-4 text-center">
                                            <img className="partnericon w-100" src={aboutpartner1} alt="aboutimg" />
                                        </div>
                                        <div className="col-md-4 text-center">
                                            <img className="partnericon w-100" src={aboutpartner} alt="aboutimg" />
                                        </div>
                                    </div>
                                </div>

                            </section>


                            <section className="aboutlists pt-5 pb-5">
                                <h4 className="text-center about-heading mb-3 crossfitFont vert__subpageTitle">{abtmid2?.description}</h4>
                                <p className="small-width max_width mb-5"><div
                                    dangerouslySetInnerHTML={{ __html:  abtmid2?.answer }} /></p>
                                <div className="fullwidth">
                                    <div className="row mt-5 justify-content-center align-items-center">
                                        {aboutlist1.map((e, i) =>
                                            <div className="col-xl-3 col-lg-4 col-md-6 col-sm-6" data-aos="fade-up" data-aos-duration="1500">
                                                <div className="card card1">
                                                    <img className="cardiconz" src={e.img ? `${config.IMG_URL}/${e.img}` : aboutprofile} alt="aboutimg" />
                                                    <div className="content text-center all1">
                                                        <p className="firaFont">{e.name}</p>
                                                        <div className="price pt-3">
                                                            <span className="price-eth cardvalue smallcont firaFont"><div
                                                                dangerouslySetInnerHTML={{ __html: e?.description?.length > 100 ? e?.description?.slice(0, 300) : e?.description }} /></span>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </section>


                            <section className="aboutcarousel aboutblog pt-5 pb-5">
                                {/* <h4 className="text-center about-heading mb-3">{abtmid3?.description}</h4>
                            <p className="small-width mb-5"><div
      dangerouslySetInnerHTML={{__html: abtmid3?.answer?.length > 100 ? abtmid3?.answer.slice(0,300) :abtmid3?.answer}}/></p> */}
                                <div className="fullwidthcarousel">
                                    {/* <OwlCarousel className='owl-theme' {...options1}>
                                    {aboutlist1.map((e, i) =>
                                        <div class='item'>
                                            <div class="card">
                                                <img class="card-img-top" src={museumimg} alt="Card image cap" />
                                                <div class="card-body cardbody text-center">
                                                    <p class="card-text mb-0">NFT 3d Rendrerd  face arts</p> */}
                                    {/* <button className="btn violenbtn mt-4" type="button">Learn More</button> */}
                                    {/* </div>
                                            </div>
                                        </div>
                                    )} */}
                                    {/* </OwlCarousel> */}
                                    <OwlCarousel className='owl-theme' {...options1}>
                                        {article.map((e, i) =>
                                           ( <div class='item' key={i}>
                                                <Link to="/blog">
                                                    <div class="card mt-3">
                                                        <img class="card-img-top" src={`${config.IMG_URL}/${e.img}`} alt="Card image cap" />
                                                        <div class="card-body">
                                                            <h5 class="card-title" >{e.heading?.length > 50 ? <>{e?.heading?.slice(0,50)}</> : e.heading}</h5>
                                                           
                                                            <p className="firaFont about__blogCardHint"><div className="firaFont"
                                                                dangerouslySetInnerHTML={{ __html: e?.content?.length > 80 ? e?.content.slice(0, 80).concat('...') : e?.content }} /></p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </div>)
                                        )}
                                    </OwlCarousel>
                                </div>
                            </section>

                            {/* <section className="aboutcontent pt-5 pb-5">
                            <h4 className="text-center about-heading mb-3">Lorem ipsum is dummy content</h4>
                            <p className="small-width mb-5">Lorem ipsum dolor sit amet consectetur. Diam faucibus mauris imperdiet a cras pharetra libero. Eget quam nibh vulputate venenatis odio aliquam. Proin massa facilisis felis mi pulvinar laoreet.</p>
                        </section> */}

                            <section className="aboutcontent pt-5 pb-5 text-center">
                                <h4 className="text-center about-heading mb-3 crossfitFont vert__subpageTitle">{abtmid3?.description}</h4>
                                <p className="small-width mb-5"><div
                                    dangerouslySetInnerHTML={{ __html: abtmid3?.answer?.length > 100 ? abtmid3?.answer.slice(0, 300) : abtmid3?.answer }} /></p>
                                <Link to="/blog">
                                    <button type="button" className="vert__cmnOutBtn">Learn</button>
                                </Link>
                            </section>

                        </div>

                    </>}
                </div>

            </div>

            <Footer />
        </>
    )
}
